<template>
  <v-container>
    <v-container>
      <v-container>
        <v-form ref="searchCondition">
          <v-row justify="center">
            検索条件
          </v-row>
          <v-row justify="center">
            <v-col md="4" cols="12">
              <ctk-date-time-picker range only-date v-model="searchDateRange" :format="dateFormat" :formatted="dateFormat" label="検索範囲の業務日付（翌日5時までを当日として計上）" :rules="rules.notNull" />
            </v-col>
            <v-col md="4" cols="12">
              <v-btn :disabled="disableBtn" @click="search">検索</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-container>
        <v-row justify="center">
            発送対象売上一覧
        </v-row>
        <v-row justify="center">
          <sales-list-data-table ref="table" :sales="sales" :headers="headers" :options="options" :disableBtn="disableBtn" :mode="mode" />
        </v-row>
      </v-container>
    </v-container>
    <v-container v-if="mode === '01'">
      <v-row>
        <v-col>
          <v-btn :disabled="disableBtn" @click="checkUpdateToTransferApplied">
            到着済みの商品の売上を振込申請する
          </v-btn>
          <v-dialog v-model="transferApplyDialog" max-width="600">
            <v-card >
              <v-card-title class="headline  font-weight-bold text-shadow" >
                ステータス更新（振込申請済みに更新）
              </v-card-title>
              <v-card-text class="font-weight-bold">
                振込申請対象の売上（到着済みのもの）： <font color="blue">{{transferApplySales.length}}</font> 件<br/>
                振込申請対象金額： <font color="blue">{{transferApplySum}}</font> 円<br/>
                送料と代行手数料 <font color="blue">{{transferApplyPutUpAgencyFeeSum}}</font> 円を差引済<br/>
                これらを振込申請済みステータスに更新しますか？<br/>
                （メルカリ等からの振込申請を行わず、<br/>
                あらかじめご自身の口座にある分から直接送金する場合も<br/>
                ステータス更新してOKです）
                <v-row justify="center">
                  <sales-list-data-table :sales="transferApplySales" :headers="transferApplySalesHeaders" :options="options" />
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="updateToTransferApplied"
                >
                  振込申請を行ったので振込申請済みステータスに更新する
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-btn :disabled="disableBtn" @click="checkUpdateToRemittanceReviewApplied">振込申請済みの商品売上を送金したので送金確認申請をする</v-btn>
          </div>
          <v-dialog v-model="remittanceReviewAppliedDialog" max-width="600">
            <v-card >

              <v-card-title class="headline  font-weight-bold text-shadow" >
                ステータス更新（送金確認申請をする）
              </v-card-title>

              <v-card-text class="font-weight-bold">
                送金対象の売上（振込申請済みのもの）： <font color="blue">{{remittanceReviewAppliedSales.length}}</font> 件<br/>
                送金対象金額： <font color="blue">{{remittanceReviewAppliedSum}}</font> 円<br/>
                送料と代行手数料  <font color="blue">{{remittanceReviewAppliedPutUpAgencyFeeSum}}</font> 円を差引済<br/>
                <br/>
                フリマアプリからの振込手数料と出品ボーナス分は、<br/>
                上記送金対象金額から適宜差し引いた額を送金ください。<br/>
                その場合、いくら差し引いたかをChatworkにてご連絡ください。<br/>
                PayPay銀行以外からの送金時の送金手数料は出品者負担です。<br/>
                <br/>
                これらを送金対象申請済みステータスに更新しますか？<br/>

              <v-card-title class="headline  font-weight-bold text-shadow" >
                送金対象の売上一覧
              </v-card-title>
                <v-row>
                  <sales-list-data-table :sales="remittanceReviewAppliedSales" :headers="remittanceReviewAppliedSalesHeaders" :options="options" />
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="updateToRemittanceReviewApplied"
                >
                  送金を行ったので送金確認申請をする
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="mode === '01'">
      <v-snackbar :color="snackbarColor" v-model="snackbar" timeout=10000 right multi-line block>{{snackbarMessage}}
        <template v-slot:action="{ attrs }">
          <v-btn
            dark
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            ×
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>
<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import DealStatusType from '../consts/deal_status_type.js'
  import ShippingMethodType from '../consts/shipping_method_type.js'
  import ValidationRules from '../consts/validation_rules.js'
  import DateUtils from '../utils/date_utils.js'
  import FirebaseStorageUtil from '../utils/firebase_storage_util.js'
  import SalesListDataTable from '../molecules/SalesListDataTable'
  import Endpoints from '../consts/api_endpoint_map.js'

  export default {
    name: "get-sales-list-template",
    components: {
      CtkDateTimePicker,
      SalesListDataTable,
    },
    props: {
      mode: {
        // 01:Normal, 02:Shippers, 03:RemittanceReviewers
        type: String,
        default: "01",
      },
      headers: {
        type: Array,
        default: () => { return [
          {text: "売上ID", value: "salesId"},
          {text: "売上日時", value: "salesDatetime"},
          {text: "売上曜日", value: "salesDay"},
          {text: "売上者名", value: "selesUserName"},
          {text: "サービス名", value: "salesServiceName"},
          {text: "商品画像", value: "itemTopImagePath"},
          {text: "商品名", value: "salesUniqueItemName"},
          {text: "サイズ", value: "itemSize"},
          {text: "色", value: "itemColor"},
          {text: "販売価格", value: "itemPrice"},
          {text: "売上価格", value: "salesPrice"},
          {text: "販売者側送料", value: "sellerShippingFee"},
          {text: "出品代行費", value: "putUpAgencyFee"},
          {text: "要送金額", value: "remittanceSum"},
          {text: "発送者側送料", value: "shipperShippingFee"},
          {text: "発送者名", value: "shippingFromUserName"},
          {text: "発送方法", value: "shippingMethodType"},
          {text: "発送先", value: "shippingToAddress"},
          {text: "取引ステータス", value: "dealStatusType"},
          {text: "取引アクション", value: "dealAction"},
        ]},
      },
      options: {
        type: Object,
        default: () => { return {
          itemsPerPage: 30          
        }}
      }
      // add if needed
    },
    data () {
      return {
        dateFormat: "YYYY-MM-DD",
        rules: ValidationRules,
        sufficientDateRange: undefined,
        searchDateRange: undefined,
        sales: [],
        dealStatusType: DealStatusType,
        shippingMethodType: ShippingMethodType,
        shippersCache: [],
        itemsCache: [],
        itemTopImagePathCache: {},
        // control
        isSalesAdmin: false,
        isShipper: false,
        disableBtn: false,
        transferApplyConfirmation: false,
        remittanceApplyConfirmation: false,
        // transfer
        transferApplySalesHeaders: [
          {text: "売上ID", value: "salesId"},
          {text: "売上業務日付", value: "salesBizDate"},
          {text: "サービス名", value: "salesServiceName"},
          {text: "商品画像", value: "itemTopImagePath"},
          {text: "商品名", value: "salesUniqueItemName"},
          {text: "売上価格", value: "salesPrice"},
          {text: "販売側送料", value: "sellerShippingFee"},
          {text: "出品代行費", value: "putUpAgencyFee"},
          {text: "要送金額", value: "remittanceSum"},
        ],
        transferApplySales: [],
        transferApplySum: 0,
        transferApplyPutUpAgencyFeeSum: 0,
        transferApplyDialog: false,
        // remittance
        remittanceReviewAppliedSalesHeaders: [
          {text: "売上ID", value: "salesId"},
          {text: "売上業務日付", value: "salesBizDate"},
          {text: "サービス名", value: "salesServiceName"},
          {text: "商品画像", value: "itemTopImagePath"},
          {text: "商品名", value: "salesUniqueItemName"},
          {text: "売上価格", value: "salesPrice"},
          {text: "販売側送料", value: "sellerShippingFee"},
          {text: "出品代行費", value: "putUpAgencyFee"},
          {text: "要送金額", value: "remittanceSum"},
        ],
        remittanceReviewAppliedSales: [],
        remittanceReviewAppliedSum: 0,
        remittanceReviewAppliedPutUpAgencyFeeSum: 0,
        remittanceReviewAppliedDialog: false,
        // snackbar
        snackbar: false,
        snackbarColor: "success",
        snackbarMessage: "",
      }
    },
    computed: {
      ...mapGetters([
        'userId',
        'roleTypes',
      ])
    },
    async created () {
      // set initial date
      const now = new Date()
      const end = DateUtils.toDatetime(now)
      now.setMonth(now.getMonth() - 1)
      const start = DateUtils.toDatetime(now)
      this.sufficientDateRange = {start: start, end: end}
      this.searchDateRange = this.sufficientDateRange
      // control
      this.isSalesAdmin = this.roleTypes.includes("01") || this.roleTypes.includes("02")
      this.isShipper = this.roleTypes.includes("04") || this.isSalesAdmin
      // do search
      await this.cacheShippers()
      await this.cacheItems()
      await this.search()
    },
    methods: {
      async rawSearch(userId, range, mode){
        // range.start/endはDatePickerの仕様に合わせる
        const res = await axios.get(Endpoints.getSalesListEndpoint + `?`
          + `userId=${userId}`
          + `&bizDateFrom=${range.start}`
          + `&bizDateTo=${range.end}`
          + `&mode=${mode}`
        )
        return res
      },
      async cacheShippers(){
        await axios.get(Endpoints.getShippersEndpoint)
          .then(res => {
            this.shippersCache = res.data
          })
          .catch(err => {
            console.log(err)
          })
      },
      async cacheItems(){
        const resItems = await axios.get(Endpoints.getItemListEndpoint)
        var items
        if(resItems.status === 200){
          items = resItems.data.responseBody.itemList
        } else {
          console.log(`error : resItems.status is ${resItems.status}`)
          console.log(resItems)
          items = []
        }
        this.itemsCache = items
      },
      async search() {
        const res = await this.rawSearch(this.userId, this.searchDateRange, this.mode)
        this.sales = res.data.responseBody.salesList
        this.setRemmittanceSum('sales')
        this.setUserName('sales')
        this.setActualImgUrl('sales', true)
      },
      setRemmittanceSum(tableDataPropertyName){
        var data = this[tableDataPropertyName]
        for(var i=0; i < data.length; i++){
          const row = data[i]
          data[i].remittanceSum = row.salesPrice
           - row.sellerShippingFee 
           - row.putUpAgencyFee
        }
        this.$set(this[tableDataPropertyName], data)
      },
      setUserName(tableDataPropertyName){
        var data = this[tableDataPropertyName]
        for(var i=0; i < data.length; i++){
          const shipper = this.shippersCache.filter(s => s.userId === data[i].shippingFromUserId)
          data[i].shippingFromUserName = shipper[0].userName
        }
        this.$set(this[tableDataPropertyName], data)        
      },
      setActualImgUrl(tableDataPropertyName, useQrImage = false){
        // have to cange this.sales directory for async process
        const vm = this
        const tableData = this[tableDataPropertyName]
        console.log(tableData.length)
        for(var i=0; i < tableData.length; i++){
          const sale = tableData[i]
          console.log("hoge?")
          console.log(i)
          console.log(sale)
            const item = this.itemsCache.find(item => item.itemId === sale.salesItemId)
          console.log("hoge!")
          // save the current i value for post-process after async url get process completed
          const getImgUrlSupport = async (i, imagePath) => {
            const url = await FirebaseStorageUtil.getImgUrl(imagePath)
            return {i: i, url: url}
          }
          getImgUrlSupport(i, item.itemTopImagePath).then(({i,url}) => {
            // cache url
            this.itemTopImagePathCache[item.itemTopImagePath] = url
            // reactive update
            var sale = tableData[i]
            sale.itemTopImagePath = url
            vm.$set(this[tableDataPropertyName], i, sale)
          })
          if(useQrImage && sale.qrImageUrl){
            getImgUrlSupport(i, tableData[i].qrImageUrl).then(({i,url}) => {
              // reactive update
              var sale = tableData[i]
              sale.qrImageUrl = url
              vm.$set(this[tableDataPropertyName], i, sale)
            })
          }
        }
          console.log("end")
      },
      sum(items, valFunc){
        return items.reduce((sum, item) => { return sum + valFunc(item) }, 0)
      },
      filterSalesByDealStatusType(checkingItems, dealStatusTypeVal){
        const items = checkingItems.filter(s => s.dealStatusType === dealStatusTypeVal)
        return items
      },
      async checkUpdateToTransferApplied(){
        // 一覧を取得して確認後、更新を実行。（一括処理のため、取得期間に応じない処理にする必要がある）
        const res = await this.rawSearch(this.userId, this.sufficientDateRange, this.mode)
        // 振込申請に遷移できる、到着済みステータスのものを取得
        const filteredSales = this.filterSalesByDealStatusType(res.data.responseBody.salesList, "06")
        this.transferApplySales = filteredSales
        this.setRemmittanceSum('transferApplySales')
        this.transferApplySum = this.sum(this.transferApplySales, sales => sales.remittanceSum)
        this.transferApplyPutUpAgencyFeeSum = this.sum(this.transferApplySales, sales => sales.putUpAgencyFee)
        this.setActualImgUrl('transferApplySales')
        if(this.transferApplySum > 0){
          this.transferApplyDialog = true
        } else {
          // snackbar
          this.snackbarColor = "error"
          this.snackbarMessage = "振込申請済へ更新できる売上（到着ステータスの売上）がありません。"
          this.snackbar = true
        }
      },
      async updateToTransferApplied(){
        var req = []
        for(const item of this.transferApplySales){
          req.push({
            salesId: item.salesId,
            statusTypeBeforeUpd: "06",
            statusTypeAfterUpd: "07",
          })
        }
        const res = await axios.post(Endpoints.updateDealStatusBatchEndpoint, req)
        console.log(res)
        for(const item of this.transferApplySales){
          this.$refs.table.updateItemValue(item.salesId, "dealStatusType", "07")
        }
        this.transferApplyDialog = false
      },
      async checkUpdateToRemittanceReviewApplied(){
        const res = await this.rawSearch(this.userId, this.sufficientDateRange, this.mode)
        const filteredSales = this.filterSalesByDealStatusType(res.data.responseBody.salesList, "07")
        this.remittanceReviewAppliedSales = filteredSales
        this.setRemmittanceSum('remittanceReviewAppliedSales')
        this.remittanceReviewAppliedSum = this.sum(this.remittanceReviewAppliedSales, sales => sales.remittanceSum)
        this.remittanceReviewAppliedPutUpAgencyFeeSum = this.sum(this.remittanceReviewAppliedSales, sales => sales.putUpAgencyFee)
        this.setActualImgUrl('remittanceReviewAppliedSales')
        if(this.remittanceReviewAppliedSum > 0){
          this.remittanceReviewAppliedDialog = true
        } else {
          // snackbar
          this.snackbarColor = "error"
          this.snackbarMessage = "送金確認依頼ができる売上（振込申請済みの売上）がありません。"
          this.snackbar = true
        }
      },
      async updateToRemittanceReviewApplied(){
        var req = []
        const date = new Date()
        for(const item of this.remittanceReviewAppliedSales){
          req.push({
            remittanceApplicationUserId: this.userId,
            remittanceApplicationDatetime: DateUtils.toDatetime(date),
            salesId: item.salesId,
            remittanceAmount: item.salesPrice - item.sellerShippingFee - item.putUpAgencyFee
          })
        }
        const res = await axios.post(Endpoints.registerRemittanceApplicationEndpoint, req)
        console.log(res)
        for(const item of this.remittanceReviewAppliedSales){
          this.$refs.table.updateItemValue(item.salesId, "dealStatusType", "08")
        }
        this.remittanceReviewAppliedDialog = false
      }
    }
  }
</script>
<style>
</style>
