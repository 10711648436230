<template>
  <get-sales-list-template></get-sales-list-template>
</template>

<script>
  import GetSalesListTemplate from '../template/GetSalesListTemplate'

  export default {
    name: "get-list",
    components: {
      GetSalesListTemplate,
    },
  }
</script>

<style>
</style>
