<template>
  <v-container>
    <v-data-table dense multi-sort :headers="headers" :items="innerSales" :options="options">
      <template v-slot:item.salesBizDate="{ item: {salesBizDate} }">
        {{ salesBizDate ? salesBizDate.substring(0,10) : "" }}
      </template>
      <template v-slot:item.salesDay="{ item: {salesDay} }">
        {{ getDayStr(salesDay) }}
      </template>
      <template v-slot:item.itemTopImagePath="{ item }">
        <v-img :src="item.itemTopImagePath" max-height="80" max-width="80" />
      </template>
      <template v-slot:item.itemSize="{ item: {itemSize} }">
        {{ itemSize ? itemSize : "-" }}
      </template>
      <template v-slot:item.itemColor="{ item: {itemColor} }">
        {{ itemColor ? itemColor : "-" }}
      </template>
      <template v-slot:item.shippingMethodType="{ item }">
        {{getTypeName(shippingMethodType, item.shippingMethodType)}}
      </template>
      <template v-slot:item.shippingToAddress="{ item }">
        <v-expansion-panels>
          <v-expansion-panel v-if="item.shippingMethodType === '01'">
            <v-expansion-panel-header>
              発送先
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              aieuo
              {{ item.shippingToAddress }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="item.shippingMethodType === '02'">
            <v-expansion-panel-header>
              QRコード
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-img :src="item.qrImageUrl" max-height="80" max-width="80" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-slot:item.dealStatusType="{ item }">
        {{getTypeName(dealStatusType, item.dealStatusType)}}
      </template>
      <template v-slot:item.dealAction="{ item }">
        <v-row v-if="isWaitingPayEnabled(item.dealStatusType)">
          <v-btn :disabled="disableBtn" @click="updateDealStatus(item.salesId, item.dealStatusType, '02')">支払待ちにする</v-btn>
        </v-row>
        <v-row v-if="isWaitingShippingEnabled(item.dealStatusType)">
          <v-btn :disabled="disableBtn" @click="updateDealStatus(item.salesId, item.dealStatusType, '03')">発送待ちにする</v-btn>
        </v-row>
        <v-row v-if="isWaitingShippingAnnouncement(item.dealStatusType)">
          <v-btn :disabled="disableBtn" @click="updateDealStatus(item.salesId, item.dealStatusType, '04')">発送完了</v-btn>
        </v-row>
         <v-row v-if="isWaitingReceiptEnabled(item.dealStatusType)">
          <v-btn :disabled="disableBtn" @click="updateDealStatus(item.salesId, item.dealStatusType, '05')">発送連絡完了</v-btn>
        </v-row>
        <v-row v-if="isReceiptEnabled(item.dealStatusType)">
          <v-btn :disabled="disableBtn" @click="updateDealStatus(item.salesId, item.dealStatusType, '06')">受取連絡を受領</v-btn>
        </v-row>
        <v-row v-if="mode === '03' && isRemittanceApplied(item.dealStatusType)">
          <v-btn :disabled="disableBtn" @click="remittanceConfirm(item)">送金確認依頼承認</v-btn>
        </v-row>
        <!-- 却下ボタン -->
      </template>
    </v-data-table>
    <v-dialog v-model="remittanceConfirmDialog" max-width="600">
      <v-card >

        <v-card-title class="headline  font-weight-bold text-shadow" >
          ステータス更新（送金確認申請承認）
        </v-card-title>

        <v-card-text class="font-weight-bold">
          送金確認申請者の氏名： {{remittanceUserName}}<br/>
          送金確認対象の売上（送金申請済みのもの）： <font color="blue">{{remittanceSales.length}}</font> 件<br/>
          送金対象金額： <font color="blue">{{remittanceSum}}</font> 円<br/>
          （他、代行手数料合計： <font color="blue">{{remittancePutUpAgencyFeeSum}}</font> 円）<br/>
          これらを送金済ステータスに更新しますか？<br/>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="updateToRemittanceFinished"
          >
            送金確認依頼承認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
  import axios from 'axios'
  import DealStatusType from '../consts/deal_status_type.js'
  import ShippingMethodType from '../consts/shipping_method_type.js'
  import Endpoints from '../consts/api_endpoint_map.js'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      sales: {
        type: Array,
        default: () => { return [] }
      },
      headers: {
        type: Array,
        default: () => { return [] }
      },
      options: {
        type: Object,
        default: () => { return {} }
      },
      disableBtn: {
        type: Boolean,
        default: false,
      },
      mode: {
        type: String,
        default: "01",
      },
    },
    data () {
      return {
        dealStatusType: DealStatusType,
        shippingMethodType: ShippingMethodType,
        innerSales: undefined,
        innerDisableBtn: undefined,
        remittanceConfirmDialog: false,
        remittanceUserName: "",
        remittanceSales: 0,
        remittanceSum: 0,
        remittancePutUpAgencyFeeSum: 0,
      }
    },
    computed: {
      shipperFlg() {
        return this.roleTypes.includes("04") // TODO hard code
      },
      ...mapGetters([
        'roleTypes'
      ])
    },
    watch: {
      sales(val){
        this.innerSales = val
      },
      disableBtn(val){
        this.innerDisableBtn = val
      }
    },
    created() {
      // v-dialog の v-model のように内部コンポーネントが描写されず、
      // salesのセット時にwatchにかからないものもセットするため、createdでもwatchによるセットと同様の処理を実装
      this.innerSales = this.sales
      this.innerDisableBtn = this.disableBtn
    },
    methods: {
      getDayStr(salesDay){
        switch(salesDay){
          case 1:
            return "日"
          case 2:
            return "月"
          case 3:
            return "火"
          case 4:
            return "水"
          case 5:
            return "木"
          case 6:
            return "金"
          case 7:
            return "土"
          default:
            return "unkdown day"
        }
      },
      getTypeName(typeList, dst){
        var name = typeList.find(elem => elem.value === dst)
        name = !name ? `未定義(${dst})` : name.name
        return name
      },
      isWaitingPayEnabled(dealStatusType){
        return dealStatusType === "01"
      },
      isWaitingShippingEnabled(dealStatusType){
        return (dealStatusType === "01" || dealStatusType === "02")
      },
      isWaitingShippingAnnouncement(dealStatusType){
        return this.shipperFlg && (dealStatusType === "03")
      },
      isWaitingReceiptEnabled(dealStatusType){
        return dealStatusType === "04"
      },
      isReceiptEnabled(dealStatusType){
        return dealStatusType === "05"
      },
      isReceipt(dealStatusType){
        return dealStatusType === "06"
      },
      isTransferApplied(dealStatusType){
        return dealStatusType === "07"
      },
      isRemittanceApplied(dealStatusType){
        return dealStatusType === "08"
      },
      async updateDealStatus(salesId, from, to){
        this.innerDisableBtn = true
        const req = {
          salesId: salesId,
          statusTypeBeforeUpd: from,
          statusTypeAfterUpd: to,
        }
        await axios.post(Endpoints.updateDealStatusEndpoint, req)
        this.updateItemValue(salesId, "dealStatusType", to)
        this.innerDisableBtn = false
      },
      updateItemValue(salesId, valName, val){
        // update display values consistent with actual updated database value
        for(var item of this.innerSales){
          if(item.salesId === salesId){
            item[valName] = val
          }
        }
      },
      getListSumSum(sales, salesUserId){
        const countingSales = sales.filter(s => s.salesUserId === salesUserId)
        const sum = countingSales.reduce((sum, item) => {
          return sum
           + item.salesPrice
           - item.sellerShippingFee 
           - item.putUpAgencyFee
         }, 0)
        const putUpAgencyFeeSum = countingSales.reduce((sum, item) => {
          return sum + item.putUpAgencyFee
        }, 0)
        return [countingSales,sum,putUpAgencyFeeSum]
      },
      async remittanceConfirm(sales){
        // const res = await this.rawSearch(this.userId, this.sufficientDateRange, this.mode)
        this.remittanceUserName = sales.salesUserName
        const listSumSum = this.getListSumSum(this.innerSales, sales.salesUserId)
        this.remittanceSales = listSumSum[0]
        this.remittanceSum = listSumSum[1]
        this.remittancePutUpAgencyFeeSum = listSumSum[2]
        if(this.remittanceSum > 0){
          this.remittanceConfirmDialog = true
        } else {
          // do nothing
        }
      },
      async updateToRemittanceFinished(){
        var req = []
        for(const sales of this.remittanceSales){
          req.push({
            salesId: sales.salesId,
            remittanceApplicationUserId: sales.salesUserId,
          })
        }
        const res = await axios.post(Endpoints.approveRemittanceEndpoint, req)
        console.log(res)
        for(const item of this.remittanceSales){
          this.updateItemValue(item.salesId, "dealStatusType", "09")
        }
        this.remittanceConfirmDialog = false
      }      
    }
  }
</script>
<style>
</style>