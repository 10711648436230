export default Object.freeze({
    numPad(num, len) {
      return ("0".repeat(len) + num).slice(-len)
    },
    toDatetime(date){
      return "" +
        this.numPad(date.getFullYear(), 4) + "-" +
        this.numPad(date.getMonth()+1, 2) +  "-" +
        this.numPad(date.getDate(), 2) + " " +
        this.numPad(date.getHours(), 2) + ":" +
        this.numPad(date.getMinutes(), 2) + ":" +
        this.numPad(date.getSeconds(), 2)
    },
})
